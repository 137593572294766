.donut-text {
    color: #007CFF;
    font-family: 'MTSCompact';
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    display: block;
    position: absolute;
    top: 35%;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
}